import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import Seo from '../components/SEO/PageSeo'

const Success = () => {
  return (
    <Layout>
      <Seo />
      <section className="success-page">
        <div className="page-center">
          <h1>Çela a marché! On vous tient au courant!</h1>
          <Link to="/" className="btn">
            retour
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default Success
